section#projects {
  .title-projects {
    border-top: 1px solid black;
  }
  .projects {
    ul {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      gap: 2rem;
      padding-left: 0rem;
      li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 1.5rem;
        border: 1px solid var(--opacity);
        img {
          padding-bottom: 2rem;
          filter: grayscale();
          &:hover {
            filter: none;
          }
        }
      }
      span {
        font-size: 13px;
      }
    }
  }
  .projects-intro {
    p {
      text-align: center;
    }
    h2 {
      text-align: center !important;
      font-size: 3rem !important;
    }
  }
}

.project-tag {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0px;
  span {
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid rgb(18, 18, 18);
    border-radius: 20px;
    margin-right: 10px;
    margin-bottom: 5px;
    white-space: nowrap;
    &:hover {
      cursor: pointer;
      background-color: var(--orange);
      border: 1px solid var(--orange);
      color: white;
    }
  }
  p {
    color: rgb(85, 85, 85) !important;
  }
}
@media only screen and (max-width: 600px) {
  .projects ul {
    display: flex !important;
    flex-direction: column;
  }
}
