@font-face {
  font-family: "Perfectly-Nineties-Regular";
  src: url("assets/fonts/perfectlynineties-regular-webfont.woff")
      format("woff2"),
    url("assets/fonts/perfectlynineties-regular-webfont.woff2") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Perfectly-Nineties-Italic";
  src: url("assets/fonts/perfectlynineties-italic-webfont.woff") format("woff2"),
    url("assets/fonts/perfectlynineties-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

/* global */
:root {
  --dark: #1a1818;
  --nude: #ece7e1;
  --orange: #fc814a;
  --purple: #a491d3;
  --plum: #88527f;
  --opacity: rgba(142, 68, 173, 0.3);
}

/* container */
body {
  font-family: "Kanit", sans-serif !important;
  font-weight: 300 !important;
  font-style: normal;
  /* background-image: url("assets/images/background.jpg");
  background-position: 0px 0px, 0px 0px;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: fixed; */
  background-color: #f9f8f7;
  height: 100%;
  position: relative;
  /* &:before {
    content: "";
    background-image: url("./assets/gradient.png");
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: 0px 0px, 0px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: scroll;
    height: 100%;
    z-index: -1;
  } */
}

section {
  padding: 15px 15px;
}
.inner {
  padding: 60px 0 !important;
}

/* fonts and styles */
h1,
h2 {
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1;
}

h3 {
  font-family: Perfectly-Nineties-Regular;
  font-weight: 600 !important;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 5rem !important;
}
h4 {
  list-style-type: none;
  margin: 0;
  font-size: 20px;
  font-weight: 500;
}
p {
  font-size: 16px !important;
  line-height: 1.5 !important;
  font-weight: 300 !important;
}

.italic {
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  font-style: italic;
}
.orange {
  color: var(--orange);
}

.plum {
  color: var(--plum);
}

.line-after {
  position: relative;
  overflow: hidden;
  display: block;
  span {
    vertical-align: baseline;
    display: block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      top: 50%;
      margin-left: 10px;
      border-top: 1px solid var(--dark);
    }
  }
}

span#highlight {
  font-weight: 600;
  background-image: linear-gradient(#fc814a, rgba(164, 145, 211, 0.3));
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 100% 2px;
  transition: background-size 0.5s, background-position 0.3s ease-in 0.5s;
  &:hover {
    background-size: 100% 100%;
    background-position: 0% 0%;
    transition: background-position 0.5s, background-size 0.3s ease-in 0.5s;
  }
}

a {
  text-transform: none !important;
  text-decoration: none !important;
  color: var(--dark) !important;
  font-weight: 600;
  &:hover {
    color: var(--orange) !important;
  }
}
